import './App.css';
import Login from './pages/Login';
import { Account, AccountContext } from './Account';
import Status from './Status';
import { useContext, useEffect } from 'react';
import Ocak from './pages/Ocak';
import { BrowserRouter, Routes, Route, Private } from 'react-router-dom';
import Izci from './pages/Izci';
import User from './pages/User';
import SelectedIzci from './pages/SelectedIzci';
import Layout from './pages/Layout';
import IzciLayout from './pages/IzciLayout';
import Navbar from './Navbar';
import PrivateRoute from './PrivateRoute';
import LoginLayout from './pages/LoginLayout';
import IzciCalibration from './pages/izciCalibration';
import OcakLayout from './pages/OcakLayout';
import SelectedOcak from './pages/SelectedOcak';
import Vana from "./pages/Vana"
import SelectedVana from './pages/SelectedVana';
import ToF from './pages/ToF';
import SelectedToF from './pages/SelectedToF';
import DeviceAlert from './pages/deviceAlert';
import Pompa from './pages/Pompa';
import SelectedPompa from './pages/SelectedPompa';
import Raw from './pages/Raw'
import ErrorRaw from './pages/ErrorRaw';
function App() {
  const { getSession, logout, status, setStatus } = useContext(AccountContext);
  function getUser() {
    getSession().then((session) => {
      console.log('Session: ', session);
      setStatus(true);
    });
  }
  useEffect(() => {
    getUser();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<LoginLayout />}>
          <Route index element={<Login />} />
        </Route>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          
          <Route path='/' element={<OcakLayout />}>
            <Route index element={<Ocak />} />
            <Route path='ocak/:id' element={<SelectedOcak />} />
          </Route>
          <Route path='izci' element={<IzciLayout />}>
            <Route index element={<Izci />} />
            <Route path=':id/calibration' element={<IzciCalibration />} />
            <Route path=':id' element={<SelectedIzci />} />
          </Route>
          <Route path='vana' element={<IzciLayout />}>
            <Route index element={<Vana />} />
            <Route path=':id' element={<SelectedVana />} />
          </Route>
          <Route path='pompa' element={<IzciLayout />}>
            <Route index element={<Pompa />} />
            <Route path=':id' element={<SelectedPompa />} />
          </Route>
          <Route path='tof' element={<IzciLayout />}>
            <Route index element={<ToF />} />
            <Route path=':id' element={<SelectedToF />} />
          </Route>
          <Route path='alert' element={<IzciLayout />}>
            <Route index element={<DeviceAlert />} />
          </Route>
          <Route path='raw' element={<IzciLayout />}>
            <Route index element={<Raw />} />
          </Route>
          <Route path='errorraw' element={<IzciLayout />}>
            <Route index element={<ErrorRaw />} />
          </Route>
          <Route path='user' element={<User />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
