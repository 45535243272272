import React, {
    useMemo,
    useEffect,
    useContext,
    useCallback,
    useState,
  } from 'react';
  import MaterialReactTable from 'material-react-table';
  import QRCode from 'react-qr-code';
  import { useParams } from 'react-router-dom';
  import { AccountContext } from '../Account';
  import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    ListItemIcon,
  } from '@mui/material';
  import { Delete, Edit, OpenInNew, Download } from '@mui/icons-material';
  import FileDownloadIcon from '@mui/icons-material/FileDownload';
  import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
  import ReactEcharts from 'echarts-for-react';
  
  
  const SelectedToF = () => {
    const { apiKey, user_id } = useContext(AccountContext);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [postdata, setpostdata] = useState(false);
    const [lastTime, setLastTime] = useState();
    const [tsdata, settsdata] = useState();
    const [columnObj, setColumnObj] = useState([]);
    const { id } = useParams();
    const [newdata, setnewdata] = useState(false);
    const [connection, setConnection] = useState(3);

  
    useEffect(() => {
      console.log("id :::", id);
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
    
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
    
      const fetchData = async () => {
        try {
          // İlk fetch işlemi
          const response1 = await fetch(
            `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
            requestOptions
          );
          const result1 = await response1.json();
          const resultfilter = result1.body;
    
          setTableData(prev => [resultfilter]);
    
          // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
          const response2 = await fetch(
            `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${resultfilter.id}&type=tof`,
            { method: "GET" }
          );
          const result2 = await response2.json();
    
          // İkinci fetch işleminin sonucunu işle
          var obj = result2.body.map((e) => JSON.parse(e));
          console.log("objilk:", obj);
          settsdata(prev=> obj);
          setfull(true);
          
          var asd = JSON.stringify(result2.body);
          // console.log('new type data : ', result.body);
          var obj = [];
          result2.body.map((e) => {
            // console.log('aha bu e:', e);
            obj.push(JSON.parse(e));
          });
          setLastTime(obj[0].received_date);
          // console.log('obj:', obj);
          settsdata(obj);
          var colmndata = [];
          var keys = Object.keys(obj?.[0]);
          // console.log("keys66",keys);
          keys.map((e) => {
            // console.log("key",e)
            let tempcol = {};
            if ( e != "measure_name") {
              tempcol.accessorKey = e;
              tempcol.header = e;
              tempcol.size = 80;
              if (e == "event_date") {
                tempcol.accessorFn = (originalRow) => {
                  var mdate = new Date(Number(originalRow.event_date));
                  mdate.setMinutes(mdate.getMinutes());
  
                  originalRow.event_date = mdate;
                  return `${mdate.getHours()}:${
                    mdate.getMinutes() < 10
                      ? `0${mdate.getMinutes()}`
                      : mdate.getMinutes()
                  }  ${mdate.getDate()}.${
                    mdate.getMonth() + 1
                  }.${mdate.getFullYear()}`;
                };
              }
              if (e == "received_date") {
                tempcol.accessorFn = (originalRow) => {
                  var mdate = new Date(Number(originalRow.received_date));
                  mdate.setMinutes(mdate.getMinutes());
  
                  originalRow.received_date = mdate;
                  return `${mdate.getHours()}:${
                    mdate.getMinutes() < 10
                      ? `0${mdate.getMinutes()}`
                      : mdate.getMinutes()
                  }  ${mdate.getDate()}.${
                    mdate.getMonth() + 1
                  }.${mdate.getFullYear()}`;
                };
              }
  
              colmndata.push(tempcol);
            }
          });
          console.log("colmndata", colmndata);
          setColumnObj(colmndata);
        } catch (error) {
          console.log("error", error);
        }
      };
    
    // fetchData fonksiyonunu çağır
    if(connection == 3){
      fetchData();
    }
      // WebSocket bağlantısını oluşturun
      var socket = new WebSocket(`wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${id}]`);
  
      // Bağlantı başarıyla kurulduğunda
      socket.addEventListener('open', (event) => {
        console.log('WebSocket bağlantısı başarıyla kuruldu.', event);
      });
      const pongInterval = setInterval(() => {
        socket.send('ping');
      }, 30000); // Her 30 saniyede bir
      // Mesaj alındığında
      socket.addEventListener('message', (event) => {
        setnewdata(prev => true)
        console.log('WebSocketten gelen mesaj:', event);
        var dat = JSON.parse(event.data)
        var parseddata = {...dat.data, ...dat.data.izci_packet}
        console.log('dat dat dat:', parseddata);
        
        settsdata(prev => [parseddata ,...prev])
        
        console.log("tsdata",tsdata)
      });
  
      // Bağlantı kapandığında
      socket.addEventListener('close', (event) => {
        console.log('WebSocket bağlantısı kapatıldı.', event);
        clearInterval(pongInterval);
        
        setTimeout(() => {
          setConnection(prev => !prev)
        }, 5000);
      });
  
      // Hata oluştuğunda
      socket.addEventListener('error', (event) => {
        console.error('WebSocket hatası:', event);
      });
      console.log("websocet kapalı triggered",socket.readyState); 
      
  
    }, [postdata,connection]);
    useEffect(() => {
      if (newdata) {
        // Animasyon bitiminde setNewData(false) işlemini gerçekleştir
        const timeoutId = setTimeout(() => {
          setnewdata(prev => false);
        }, 1000); // Animasyon süresini burada belirleyebilirsiniz (örnekte 1 saniye)
        
        // Temizlik işlemleri
        return () => clearTimeout(timeoutId);
      }
    }, [newdata]);
    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        var myHeaders = new Headers();
        myHeaders.append('apikey', apiKey);
        myHeaders.append('userid', user_id);
        var updatedValues = values;
        var id = values.id;
        delete updatedValues.id;
        delete updatedValues.produced_at;
  
        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(values),
        };
        console.log(values);
        fetch(
          `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log(result))
          .then(setpostdata(!postdata))
          .catch((error) => console.log('error', error));
  
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };
  
    const handleCancelRowEdits = () => {
      setValidationErrors({});
    };
  
    const handleDeleteRow = useCallback(
      (row) => {
        //
        if (
          !window.confirm(`Are you sure you want to delete ${row.getValue('id')}`)
        ) {
          return;
        }
        console.log('deleted');
        var myHeaders = new Headers();
        myHeaders.append('apikey', apiKey);
        myHeaders.append('userid', user_id);
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
        };
  
        fetch(
          `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${row.getValue(
            'id'
          )}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log(result))
          .then(setpostdata(!postdata))
          .catch((error) => console.log('error', error));
      },
      [tableData]
    );
  
    const getCommonEditTextFieldProps = useCallback(
      (cell) => {
        return {
          error: !!validationErrors[cell.id],
          helperText: validationErrors[cell.id],
          onBlur: (event) => {
            const isValid = validateRequired(event.target.value);
            if (!isValid) {
              //set validation error for cell if invalid
              setValidationErrors({
                ...validationErrors,
                [cell.id]: `${cell.column.columnDef.header} is required`,
              });
            } else {
              //remove validation error for cell if valid
              delete validationErrors[cell.id];
              setValidationErrors({
                ...validationErrors,
              });
            }
          },
        };
      },
      [validationErrors]
    );
    function setLast(time) {
      var mdate = new Date(Number(time));
              mdate.setMinutes(mdate.getMinutes());
  
              time = mdate;
              return `${mdate.getHours()}:${
                mdate.getMinutes() < 10
                  ? `0${mdate.getMinutes()}`
                  : mdate.getMinutes()
              }  ${mdate.getDate()}.${
                mdate.getMonth() + 1
              }.${mdate.getFullYear()}`;
  }
    const columns = useMemo(
      () => [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          enableClickToCopy: true,
          size: 80,
        },
        {
          accessorKey: 'izci_length',
          header: 'length',
          enableEditing: true, //disable editing on this column
          size: 80,
        },
        {
          accessorKey: 'izci_hw_group',
          header: 'hw_group',
          enableEditing: true, //disable editing on this column
          size: 80,
        },
  
        {
          accessorKey: 'izci_hw_cfg',
          header: 'hw_cfg',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_packet_ver',
          header: 'packet_ver',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_hw_ver',
          header: 'hw_ver',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_hw_rev',
          header: 'hw_rev',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_sw_ver',
          header: 'sw_ver',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
  
        {
          accessorKey: 'produced_at',
          header: 'produced_at',
          enableEditing: false, //disable editing on this column
          size: 80,
        },
        {
          accessorKey: 'owner_id',
          header: 'owner_id',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'qr',
          header: 'qr',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          size: 80,
          Cell: ({ cell }) => (
            <>
              <QRCode
                id={cell.row.original.id}
                size={256}
                style={{
                  height: 'auto',
                  width: '70px',
                }}
                value={cell.row.original.id}
                viewBox={`0 0 256 256`}
              />
            </>
          ),
        },
      ],
      [getCommonEditTextFieldProps]
    );
    const columns90 = useMemo(
      () => [...columnObj
        
      ],
      [columnObj,getCommonEditTextFieldProps]
    );

    const downloadQRCode = (row) => {
      console.log('row : ', row.original);
      var svg = document.getElementById(row.original.id);
  
      //get svg source.
      var serializer = new XMLSerializer();
      var source = serializer.serializeToString(svg);
  
      //add name spaces.
      if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }
  
      //add xml declaration
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
  
      //convert svg source to URI data scheme.
      var url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
  
      //set url value to a element's href attribute.
  
      //you can download svg file by right click menu.
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${row.original.id}.svg`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    const [full, setfull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [time, settime] = useState([]);
    const [value, setvalue] = useState([]);
    const [max, setmax] = useState();
    const [min, setmin] = useState();
  
    function yaz(attrname) {
      var time = [];
      var value = [];
      var lasttime;
      tsdata?.map((data) => {
        if (Date.parse(data.time) - lasttime < -7200000) {
          value.push([Date.parse(data.time) + 3200000, null]);
        }
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() );
        value.push([mdate, Number(data[attrname]).toFixed(2)]);
  
        lasttime = Date.parse(data.time);
      });
      return value;
    }
  
   
    const DrawStackedTSSM = (data) => {
      switch (data && data[0]?.izci_length? data[0].izci_length :"") {
        case '60':
          var graphData1 = yaz('sm5');
          var graphData2 = yaz('sm15');
          var graphData3 = yaz('sm25');
          var graphData4 = yaz('sm35');
          var graphData5 = yaz('sm45');
          var graphData6 = yaz('sm55');
          return StackedLine(
            [
              graphData1,
              graphData2,
              graphData3,
              graphData4,
              graphData5,
              graphData6,
            ],
            ['sm05', 'sm15', 'sm25', 'sm35', 'sm45', 'sm55']
          );
        case '90':
          var graphData1 = yaz('sm5');
          var graphData2 = yaz('sm15');
          var graphData3 = yaz('sm25');
          var graphData4 = yaz('sm35');
          var graphData5 = yaz('sm45');
          var graphData6 = yaz('sm55');
          var graphData7 = yaz('sm65');
          var graphData8 = yaz('sm75');
          var graphData9 = yaz('sm85');
          return StackedLine(
            [
              graphData1,
              graphData2,
              graphData3,
              graphData4,
              graphData5,
              graphData6,
              graphData7,
              graphData8,
              graphData9,
            ],
            [
              'sm05',
              'sm15',
              'sm25',
              'sm35',
              'sm45',
              'sm55',
              'sm65',
              'sm75',
              'sm85',
            ]
          );
        default:
          var graphData1 = yaz('sm5');
          var graphData2 = yaz('sm15');
          var graphData3 = yaz('sm25');
          return StackedLine(
            [graphData1, graphData2, graphData3],
            ['sm05', 'sm15', 'sm25']
          );
      }
    };
    const DrawStackedTSSMMemo = useMemo(() => DrawStackedTSSM(tableData), [tableData])
    const DrawStackedTSST = (data) => {
      switch (data && data[0]?.izci_length? data[0].izci_length :"") {
        case '60':
          var graphData1 = yaz('st5');
          var graphData2 = yaz('st15');
          var graphData3 = yaz('st25');
          var graphData4 = yaz('st35');
          var graphData5 = yaz('st45');
          var graphData6 = yaz('st55');
          return StackedLine(
            [
              graphData1,
              graphData2,
              graphData3,
              graphData4,
              graphData5,
              graphData6,
            ],
            ['st05', 'st15', 'st25', 'st35', 'st45', 'st55']
          );
        case '90':
          var graphData1 = yaz('st5');
          var graphData2 = yaz('st15');
          var graphData3 = yaz('st25');
          var graphData4 = yaz('st35');
          var graphData5 = yaz('st45');
          var graphData6 = yaz('st55');
          var graphData7 = yaz('st65');
          var graphData8 = yaz('st75');
          var graphData9 = yaz('st85');
          return StackedLine(
            [
              graphData1,
              graphData2,
              graphData3,
              graphData4,
              graphData5,
              graphData6,
              graphData7,
              graphData8,
              graphData9,
            ],
            [
              'st05',
              'st15',
              'st25',
              'st35',
              'st45',
              'st55',
              'st65',
              'st75',
              'st85',
            ]
          );
        default:
          var graphData1 = yaz('st5');
          var graphData2 = yaz('st15');
          var graphData3 = yaz('st25');
          return StackedLine(
            [graphData1, graphData2, graphData3],
            ['st05', 'st15', 'st25']
          );
      }
    };
    const DrawStackedTSSTMemo = useMemo(() => DrawStackedTSST(tableData), [tableData])
    const DrawStackedTSAT = (data) => {
      var graphData1 = yaz('at');
      return StackedLine([graphData1], ['at']);
    };
    const DrawStackedTSATMemo = useMemo(() => DrawStackedTSAT(tableData), [tableData])
    const DrawStackedTSSF = (data) => {
      switch (data && data[0]?.izci_length? data[0].izci_length :"") {
        case '60':
          var graphData1 = yaz('sf5');
          var graphData2 = yaz('sf15');
          var graphData3 = yaz('sf25');
          var graphData4 = yaz('sf35');
          var graphData5 = yaz('sf45');
          var graphData6 = yaz('sf55');
          return StackedLine(
            [
              graphData1,
              graphData2,
              graphData3,
              graphData4,
              graphData5,
              graphData6,
            ],
            ['sf05', 'sf15', 'sf25', 'sf35', 'sf45', 'sf55']
          );
        case '90':
          var graphData1 = yaz('sf5');
          var graphData2 = yaz('sf15');
          var graphData3 = yaz('sf25');
          var graphData4 = yaz('sf35');
          var graphData5 = yaz('sf45');
          var graphData6 = yaz('sf55');
          var graphData7 = yaz('sf65');
          var graphData8 = yaz('sf75');
          var graphData9 = yaz('sf85');
          return StackedLine(
            [
              graphData1,
              graphData2,
              graphData3,
              graphData4,
              graphData5,
              graphData6,
              graphData7,
              graphData8,
              graphData9,
            ],
            [
              'sf05',
              'sf15',
              'sf25',
              'sf35',
              'sf45',
              'sf55',
              'sf65',
              'sf75',
              'sf85',
            ]
          );
        default:
          var graphData1 = yaz('sf5');
          var graphData2 = yaz('sf15');
          var graphData3 = yaz('sf25');
          return StackedLine(
            [graphData1, graphData2, graphData3],
            ['sf05', 'sf15', 'sf25']
          );
      }
    };
    const DrawStackedTSSFMemo = useMemo(() => DrawStackedTSSF(tableData), [tableData])
    var csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns90.map((c) => c.header)
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    
    const handleExportRows = (rows) => {
      
     
      
      csvExporter.generateCsv(rows.map((row) => {
        var ordered={}
        columns90.map((c)=>ordered[c.header] = row.original[c.header])
        return ordered
  
      } ));
    };
  
    
    const CommandFunction = (values)=>{
        var myHeaders = new Headers();
        myHeaders.append('apikey', apiKey);
        myHeaders.append('userid', user_id);
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(values),
        };
        console.log(values);
        fetch(
          `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log(result))
          .catch((error) => console.log('error', error));
    }
    return (
      <>
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode='modal' //default
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          enableRowNumbers
          enableRowActions
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableGlobalFilter={false}
          enableHiding={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          renderRowActionMenuItems={({ closeMenu, table, row }) => [
            <MenuItem
              key={0}
              onClick={() => {
                table.setEditingRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Edit
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                handleDeleteRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              Delete
            </MenuItem>,
  
            <MenuItem
              key={3}
              onClick={() => {
                downloadQRCode(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              Download QR Code
            </MenuItem>,
          ]}
        />
        <>

        
          <h1 className='text-4xl m-5 flex flex-row max-sm:flex-col gap-3 '>
            Measurements{' '}
            <p className='flex items-end text-xl text-blue-600'>
              last measure: {tsdata && setLast(tsdata[0]?.received_date)}
            </p>
          </h1>
          
           
              <div className='mb-12'>
                <MaterialReactTable
                  columns={
                   columns90
                      
                  }
                  data={full ? tsdata:[]}
                  enableRowNumbers
              enableFullScreenToggle={true}
              enableDensityToggle={false}
              enableGlobalFilter={false}
              enableHiding={false}
              enableColumnFilters={false}
              enablePagination={true}
              autoResetPageIndex={false}
              enableRowVirtualization
              
              rowVirtualizerProps={{ overscan: 8 }} //optionally customize the virtualizer
              enableStickyHeader
              muiTablePaginationProps={{
                rowsPerPageOptions: [10,50,200,1000],
                showFirstButton: false,
                showLastButton: false,
              }}
              enableColumnActions={true}
              enableBottomToolbar={true}
              enableTopToolbar={true}
              enableRowSelection
              initialState={{
                density: 'compact'
              }}
              state={{
  
                isLoading: !full
              }}
              positionToolbarAlertBanner="bottom"
                  renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                      >
                        
                        <Button
                          disabled={table.getPrePaginationRowModel().rows.length === 0}
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                          }
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Export All Data
                        </Button>
                        
                        <Button
                          disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Export Selected Data
                        </Button>
                      </Box>
                  )}
                  memoMode="cells"
                  muiTableBodyRowProps={({
                    row
                  }) => ({
                    sx: {
                      
                      animation: newdata & row.id < 1 ?"blink 1s ":"",
                      '@keyframes blink': {
                        '0%': { backgroundColor: 'white' },
                        '50%': { backgroundColor: '#fffd00' },
                        '100%': { backgroundColor: 'white' },
                      },
                     
                    }
                  })}
                />
              </div>
           
          {/* {!full == true ? (
            <div className=' flex flex-row flex-wrap gap-5 ml-5 mr-5 '>
              <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
              <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
              <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
              <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
            </div>
          ) : (
            <div className='max-sm:flex-col flex flex-row flex-wrap gap-5 ml-5 mr-5 max-sm:ml-0 max-sm:mr-0 mb-16'>
              <div className='max-w-full min-w-0 w-1/3  max-sm:w-full bg-white'>
                <p className='relative z-20 text-lg font-bold  p-3 '>
                  Soil Moisture %{' '}
                </p>
                {DrawStackedTSSMMemo}
              </div>
              <div className='max-w-full min-w-0 w-1/3 max-sm:w-full  bg-white'>
                <p className='relative z-20 text-lg font-bold  p-3 '>
                  Soil Temperature (°C){' '}
                </p>
                {DrawStackedTSSTMemo}
              </div>
              <div className='max-w-full min-w-0 w-1/4 max-sm:w-full  bg-white'>
                <p className='relative z-20 text-lg font-bold  p-3 '>
                  Air Temperature (°C){' '}
                </p>
                {DrawStackedTSATMemo}
              </div>
              <div className='max-w-full min-w-0 w-1/3 max-sm:w-full  bg-white'>
                <p className='relative z-20 text-lg font-bold  p-3 '>
                  EC (dS/m){' '}
                </p>
                {DrawStackedTSSMMemo}
              </div>
              <div className='max-w-full min-w-0 w-7/12 max-sm:w-full  bg-white'>
                <p className='relative z-20 text-lg font-bold  p-3 '>
                  Frequency (dS/m){' '}
                </p>
                {DrawStackedTSSFMemo}
              </div>
            </div>
          )} */}
        </>
      </>
    );
  };
  
  const validateRequired = (value) => !!value.length;
  
  export default SelectedToF;
  
  function StackedLine(rawdatas, names) {
    let option = {
      // title: {
      //   show: false,
      //   text: ' asd',
      //   display: 'none',
      // },
  
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        icon: 'roundRect',
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          brushSelect: false,
          handleStyle: {
            borderColor: 'rgba(0, 0, 0, 1)',
          },
          moveHandleSize: 16,
        },
        {
          type: 'inside',
        },
      ],
      grid: [
        {
          left: '3%',
          right: '5%',
          bottom: '0%',
          top: '10%',
          height: '85%',
          containLabel: true,
        },
      ],
      axisLine: {
        show: false,
      },
      xAxis: {
        type: 'time',
        axisPointer: {
          snap: false,
          lineStyle: {
            color: '#ff6600',
            width: 2,
          },
        },
      },
      yAxis: {
        splitLine: { show: false },
        splitArea: {
          show: true,
        },
        axisLine: { show: true },
        type: 'value',
        max: function (value) {
          return value.max + 10;
        },
        min: function (value) {
          return value.min - 0;
        },
      },
  
      series: [],
      seriesCnt: '3',
      backgroundColor: 'rgba(255,255,255,1)',
      titleColor: '#000000',
      subtitleColor: '#505050',
      textColorShow: false,
      textColor: '#333',
      markTextColor: '#ffffff',
      color: ['#2a10ac', '#00b7fb', '#df07dc'],
      borderColor: '#ccc',
      borderWidth: '0',
      visualMapColor: ['#8a7ca8', '#e098c7', '#cceffa'],
      legendTextColor: '#000000',
      kColor: '#e098c7',
      kColor0: 'transparent',
      kBorderColor: '#e098c7',
      kBorderColor0: '#8fd3e8',
      kBorderWidth: '2',
      lineWidth: '1',
      symbolSize: '0',
      symbol: 'emptyRoundRect',
      symbolBorderWidth: '0',
      lineSmooth: false,
      graphLineWidth: 1,
      graphLineColor: '#aaaaaa',
      mapLabelColor: '#000',
      mapLabelColorE: '#ffffff',
      mapBorderColor: '#444',
      mapBorderColorE: '#444',
      mapBorderWidth: 0.5,
      mapBorderWidthE: 1,
      mapAreaColor: '#eee',
      mapAreaColorE: '#e098c7',
      axes: [
        {
          type: 'all',
          name: '通用坐标轴',
          axisLineShow: true,
          axisLineColor: '#000000',
          axisTickShow: false,
          axisTickColor: '#333',
          axisLabelShow: true,
          axisLabelColor: '#000000',
          splitLineShow: false,
          splitLineColor: ['#eeeeee', '#333333'],
          splitAreaShow: false,
          splitAreaColor: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
        {
          type: 'category',
          name: '类目坐标轴',
          axisLineShow: true,
          axisLineColor: '#333',
          axisTickShow: true,
          axisTickColor: '#333',
          axisLabelShow: true,
          axisLabelColor: '#333',
          splitLineShow: false,
          splitLineColor: ['#ccc'],
          splitAreaShow: false,
          splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
        {
          type: 'value',
          name: '数值坐标轴',
          axisLineShow: true,
          axisLineColor: '#333',
          axisTickShow: true,
          axisTickColor: '#333',
          axisLabelShow: true,
          axisLabelColor: '#333',
          splitLineShow: true,
          splitLineColor: ['#ccc'],
          splitAreaShow: false,
          splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
        {
          type: 'log',
          name: '对数坐标轴',
          axisLineShow: true,
          axisLineColor: '#333',
          axisTickShow: true,
          axisTickColor: '#333',
          axisLabelShow: true,
          axisLabelColor: '#333',
          splitLineShow: true,
          splitLineColor: ['#ccc'],
          splitAreaShow: false,
          splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
        {
          type: 'time',
          name: '时间坐标轴',
          axisLineShow: true,
          axisLineColor: '#333',
          axisTickShow: true,
          axisTickColor: '#333',
          axisLabelShow: true,
          axisLabelColor: '#333',
          splitLineShow: true,
          splitLineColor: ['#ccc'],
          splitAreaShow: false,
          splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      ],
      axisSeperateSetting: false,
      toolboxColor: '#4100ff',
      toolboxEmphasisColor: '#000000',
      tooltipAxisColor: '#0013ff',
      tooltipAxisWidth: '3',
      timelineLineColor: '#8fd3e8',
      timelineLineWidth: 1,
      timelineItemColor: '#ff0000',
      timelineItemColorE: '#8fd3e8',
      timelineCheckColor: '#8fd3e8',
      timelineCheckBorderColor: '#8a7ca8',
      timelineItemBorderWidth: 1,
      timelineControlColor: '#8fd3e8',
      timelineControlBorderColor: '#8fd3e8',
      timelineControlBorderWidth: 0.5,
      timelineLabelColor: '#8fd3e8',
      datazoomBackgroundColor: 'rgba(0,0,0,0)',
      datazoomDataColor: 'rgba(255,255,255,0.3)',
      datazoomFillColor: 'rgba(167,183,204,0.4)',
      datazoomHandleColor: '#a7b7cc',
      datazoomHandleWidth: '100',
      datazoomLabelColor: '#333',
    };
    rawdatas.map((e, index) => {
      option.series.push({
        name: names[index],
        type: 'line',
        data: e,
        lineStyle: {
          width: 4,
        },
      });
    });
    return <ReactEcharts option={option} className=' z-10' />;
  }
  