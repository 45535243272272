import React, { useContext, useEffect, useState } from "react";
import Logo from "./assests/suyabakan_logo_svg.svg";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { AccountContext } from "./Account";

function Navbar() {
  const [userDropDown, setUserDropDown] = useState(false);
  const [menuDropDown, setMenuDropDown] = useState(false);
  const { getSession, logout, status, setStatus } = useContext(AccountContext);
  const navigate = useNavigate();
  return (
    <nav className="bg-white">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setMenuDropDown(!menuDropDown)}
            >
              <span className="sr-only">Open main menu</span>
              {/* <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          --> */}
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              {/* <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          --> */}
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img src={Logo} className=" block h-10 w-auto lg:hidden " />
              <img src={Logo} className=" hidden h-10 w-auto lg:block " />
            </div>

            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-500 hover:text-white" --> */}
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  Ocak
                </NavLink>

                <NavLink
                  to="/izci"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  İzci
                </NavLink>
                <NavLink
                  to="/vana"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  Vana
                </NavLink>
                <NavLink
                  to="/pompa"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  Pompa
                </NavLink>
                <NavLink
                  to="/tof"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  ToF
                </NavLink>
                <NavLink
                  to="/alert"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  Alert
                </NavLink>
                <NavLink
                  to="/user"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  User
                </NavLink>
                <NavLink
                  to="/raw"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  Raw
                </NavLink>
                <NavLink
                  to="/errorraw"
                  className={({ isActive }) =>
                    isActive
                      ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                      : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
                  }
                >
                  Hatalı Veri
                </NavLink>
              </div>
            </div>
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              type="button"
              className="rounded-full bg-gray-200 p-1 text-gray-400 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </button>

            {/* <!-- Profile dropdown --> */}
            <div className="relative ml-3">
              <div>
                <button
                  type="button"
                  className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={() => setUserDropDown(!userDropDown)}
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </button>
              </div>

              {/* <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
              {userDropDown && (
                <div
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex="-1"
                >
                  {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-0"
                  >
                    Your Profile
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-1"
                  >
                    Settings
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-2"
                    onClick={() => {
                      logout();
                      navigate("/login");
                    }}
                  >
                    Sign out
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      {menuDropDown && (
        <div className="sm:hidden overflow-scroll" id="mobile-menu">
          <div className="space-y-1 px-2 pt-2 pb-3">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-500 hover:text-white" --> */}
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              Ocak
            </NavLink>

            <NavLink
              to="/izci"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              İzci
            </NavLink>
            <NavLink
              to="/vana"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              Vana
            </NavLink>
            <NavLink
              to="/pompa"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              Pompa
            </NavLink>
            <NavLink
              to="/tof"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              ToF
            </NavLink>
            <NavLink
              to="/alert"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              Alert
            </NavLink>
            <NavLink
              to="/user"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              User
            </NavLink>
            <NavLink
              to="/raw"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium "
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white"
              }
            >
              Raw
            </NavLink>
            <NavLink
              to="/errorraw"
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-800  text-white px-3 py-2 rounded-md text-sm font-medium whitespace-nowrap"
                  : "text-gray-500 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white whitespace-nowrap"
              }
            >
              Hatalı Veri
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
